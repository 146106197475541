import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/index';
import MenuComponent from '@components/common/Menu';
import { CaretDownIcon } from '@components';
import BlackTooltip from '@components/common/BlackTooltip';
import Colors from 'styles/colors';

const SimpleSelect = ({
  values,
  label,
  value,
  prefix,
  setValue,
  tooltipContent,
  buttonClassNames,
  iconColor
}) => {
  const [anchor, setAnchor] = useState(null);
  const handleClick = event => {
    setAnchor(event.currentTarget);
  };

  const handleClose = newValue => {
    setAnchor(null);
    if (typeof newValue === 'string') {
      setValue(newValue);
    }
  };

  return (
    <>
      <BlackTooltip
        arrow
        title={tooltipContent}
        placement="bottom"
        open={tooltipContent !== null ? undefined : false}
      >
        <Button
          aria-haspopup="true"
          className={classNames(
            'flex flex-row items-center text-sm text-gray-darkest font-medium capitalize p-2',
            buttonClassNames
          )}
          onClick={handleClick}
        >
          <span>{prefix}</span>
          <span>{label}</span>
          {!!label && <span className="mr-1">:</span>}
          <span>{value}</span>
          <CaretDownIcon
            fill={iconColor}
            width="16px"
            height="14px"
            className="ml-2 hidden xs:block"
          />
        </Button>
      </BlackTooltip>
      <MenuComponent
        id={label}
        data={values}
        handleClose={handleClose}
        anchor={anchor}
        className="w-full"
      />
    </>
  );
};

SimpleSelect.propTypes = {
  value: PropTypes.string.isRequired || PropTypes.node.isRequired,
  setValue: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  tooltipContent: PropTypes.string,
  prefix: PropTypes.string,
  iconColor: PropTypes.string
};

SimpleSelect.defaultProps = {
  label: '',
  tooltipContent: null,
  buttonClassNames: '',
  iconColor: Colors.gray.default
};

export default SimpleSelect;
