import axios from 'axios';
import API, {
  APP_VERSION_HTTP_HEADER,
  DEFAULT_REQUEST_TIMEOUT,
  GLOBAL_NOTIFICATION_HTTP_HEADER,
  HOST
} from '@sm/api';
import {
  EXISTED_EMAIL_ERROR_TEXT,
  HTTP_409_CONFLICT,
  INCORRECT_CREDENTIALS_ERROR_TEXT
} from './network';
import { getFilteredRequestData, sendTextError } from './sentry';

const handleGlobalSystemNotification = (
  notificationId,
  store,
  handleGlobalSystemNotificationAction
) => {
  if (!notificationId) return;
  store.dispatch(handleGlobalSystemNotificationAction(notificationId));
};

const addUserId = (config, userId) => {
  const { method } = config;
  if (method === 'get')
    return { ...config, params: config.params ? { ...config.params, userId } : { userId } };
  if (config.data instanceof FormData) {
    config.data.append('user_id', userId);
    return config;
  }
  return {
    ...config,
    data: config.data ? { ...config.data, userId: config.data.userId || userId } : { userId }
  };
};

export const initAxiosRequestsInterceptor = store => {
  axios.interceptors.request.use(
    _config => {
      const { user, login } = store.getState();
      const config =
        user.userId && !_config?.params?.userId ? addUserId(_config, user.userId) : _config;
      if (!config.url.includes(HOST)) return config;
      const authorization = login.token ? { Authorization: `JWT ${login.token}` } : {};
      return {
        ...config,
        headers: {
          ...config.headers,
          ...authorization
        },
        timeout: config.timeout || DEFAULT_REQUEST_TIMEOUT
      };
    },
    error => Promise.reject(error)
  );
};

const getSendError = errorHandler => response => {
  const { status, statusText, config, data } = response;
  const dataToSend = {
    url: config?.url,
    params: config?.params,
    filteredRequestData: getFilteredRequestData(config?.data),
    status,
    statusText,
    responseData: JSON.stringify(data)
  };
  errorHandler(`StatusCode: ${dataToSend.status} ${dataToSend.url}`, dataToSend);
};

const getIsIgnoredError = error => {
  const errorKeys = Object.keys(error);
  return (
    (!errorKeys.includes('response') && !errorKeys.includes('request')) ||
    error.response?.status === HTTP_409_CONFLICT
  );
};

const signUpErrorsHandler = (response, sendError) => {
  if (response.data === EXISTED_EMAIL_ERROR_TEXT) return;
  sendError(response);
};

const loginErrorsHandler = (response, sendError) => {
  if (response?.data?.non_field_errors?.[0] === INCORRECT_CREDENTIALS_ERROR_TEXT) return;
  sendError(response);
};

export const initAxiosResponseInterceptor = (
  errorHandler,
  store,
  handleAppVersion,
  cleanupSystemNotificationsAction,
  handleSystemErrorsAction,
  handleGlobalSystemNotificationAction
) => {
  axios.interceptors.response.use(
    response => {
      if (cleanupSystemNotificationsAction) store.dispatch(cleanupSystemNotificationsAction());
      if (handleAppVersion) {
        handleAppVersion(response?.request?.getResponseHeader(APP_VERSION_HTTP_HEADER));
      }
      if (handleGlobalSystemNotificationAction) {
        handleGlobalSystemNotification(
          response?.request?.getResponseHeader(GLOBAL_NOTIFICATION_HTTP_HEADER),
          store,
          handleGlobalSystemNotificationAction
        );
      }
      return response;
    },
    error => {
      const interceptError = () => {
        const sendError = getSendError(errorHandler);
        try {
          if (handleAppVersion) {
            handleAppVersion(error?.request?.getResponseHeader(APP_VERSION_HTTP_HEADER), true);
          }
        } catch (e) {
          console.log('getHandleAppVersion error', e);
          sendTextError('Handle app version control failed');
        }
        try {
          if (handleSystemErrorsAction) store.dispatch(handleSystemErrorsAction(error));
          if (getIsIgnoredError(error) || !error.response) return;
          const { response } = error;
          const url = response?.config?.url;
          if (API.signup.includes(url)) {
            signUpErrorsHandler(response, sendError);
            return;
          }
          if (API.login.includes(url)) {
            loginErrorsHandler(response, sendError);
            return;
          }
          sendError(response);
        } catch (e) {
          console.log('axiosErrorInterceptor error:', e);
        }
      };
      interceptError();
      throw error;
    }
  );
};
