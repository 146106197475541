import API from '@sm/api';
import mock from '../mock';

const brandsResponse = [
  {
    email: 'danny@gyrostate.com',
    name: 'Tumaz',
    client_id: 3975,
    status: 'Active'
  },
  {
    email: 'amandamok2020@gmail.com',
    name: 'Marmolux',
    client_id: 3889,
    status: 'Active'
  },
  {
    email: '229471172@qq.com',
    name: 'MAYSHINE',
    client_id: 3863,
    status: 'Active'
  },
  {
    email: '952489825@qq.com',
    name: 'ITSOFT',
    client_id: 4037,
    status: 'Active'
  },
  {
    email: 'babydeedee@rainforest.life',
    name: 'Baby DeeDee',
    client_id: 4339,
    status: 'Active'
  },
  {
    email: 'rick@rainforest.life',
    name: 'Feel At Home',
    client_id: 4658,
    status: 'Active'
  },
  {
    email: 'info@pooplikeachampion.com',
    name: 'Poop Like A Champion',
    client_id: 4657,
    status: 'Active'
  },
  {
    email: 'info@moozikpro.com',
    name: 'AFA Tooling',
    client_id: 4659,
    status: 'Active'
  },
  {
    email: 'ritesh@rainforest.life',
    name: 'Aum Active',
    client_id: 4667,
    status: 'Active'
  },
  {
    email: 'daniel7rusu@gmail.com',
    name: 'Easy Baby',
    client_id: 4661,
    status: 'Active'
  },
  {
    email: 'peter@personacreations.ca',
    name: "Lilly's Love",
    client_id: 4662,
    status: 'Active'
  },
  {
    email: '181602141@qq.com',
    name: 'MBIGM',
    client_id: 4663,
    status: 'Active'
  },
  {
    email: 'welavila@rainforest.life',
    name: 'Welavila',
    client_id: 4665,
    status: 'Active'
  },
  {
    email: '595038197@qq.com',
    name: 'Babbleroo',
    client_id: 4672,
    status: 'Active'
  },
  {
    email: 'chemperai@rainforest.life',
    name: 'Chemperai',
    client_id: 4685,
    status: 'Active'
  },
  {
    email: 'mihaiovidiuparaschiv@gmail.com',
    name: 'Panther Armor',
    client_id: 4778,
    status: 'Active'
  },
  {
    email: 'info@comfybumpy.com',
    name: 'Comfy Bumpy',
    client_id: 5807,
    status: 'Active'
  },
  {
    email: 'babbligsc@rainforest.life',
    name: 'Babblig',
    client_id: 5820,
    status: 'Active'
  }
];

mock.onGet(API.agency_clients).reply(async config => {
  console.log('config', config);
  await new Promise(resolve => setTimeout(resolve, 250));
  return [200, brandsResponse];
});

mock.onGet(API.insights.objectives).reply(async config => {
  console.log('insights.objectives config', config);
  await new Promise(resolve => setTimeout(resolve, 150));
  return [
    200,
    [
      {
        title: 'Weekly Review',
        description:
          'Weekly review of the data. Compare the data with the previous week and identify the trends.',
        period: 'Week'
      },
      {
        title: 'Weekly Anomaly',
        description: 'Conduct a weekly analysis of data using a 7-week moving average approach.',
        period: 'Week'
      }
    ]
  ];
});

const chartEndUserResponse = {
  values: {
    gross_profit_wbr_calc: [
      [1714262400000, 8646.517],
      [1714348800000, 8526.7045],
      [1714435200000, 9155.7573],
      [1714521600000, 10076.5038],
      [1714608000000, 10860.5358, 'positive'],
      [1714694400000, 8415.3072],
      [1714780800000, 10040.746],
      [1714867200000, 10911.3439],
      [1714953600000, 10045.7979],
      [1715040000000, 9117.8339, 'neutral'],
      [1715126400000, 9681.472],
      [1715212800000, 9916.4705],
      [1715299200000, 8839.4057],
      [1715385600000, 8291.9158, 'negative'],
      [1715472000000, 10228.873],
      [1715558400000, 10773.0039],
      [1715644800000, 8790.5015]
    ]
  },
  insights: {
    1714608000000: {
      'Check targets': {
        summary:
          '<h2>2024-05-02</h2><h3 clickable="true" id="drivers">Drivers</h3><ul><li clickable="true" id="product_sales">product_sales</li><li clickable="true" id="net_referral_fees_calc">net_referral_fees_calc</li><li clickable="true" id="cogs_calc">cogs_calc</li></ul>',
        details: {
          sub_metric_1: {
            show_in_chart: true,
            show_in_table: true,
            minor: true,
            units: '$',
            values: [
              { timestamp: 1714262400000, value: 250 },
              { timestamp: 1714348800000, value: 250 },
              { timestamp: 1714435200000, value: 250 },
              { timestamp: 1714521600000, value: 250 },
              { timestamp: 1714608000000, value: 250 },
              { timestamp: 1714694400000, value: 250 },
              { timestamp: 1714780800000, value: 250 },
              { timestamp: 1714867200000, value: 250 },
              { timestamp: 1714953600000, value: 250 },
              { timestamp: 1715040000000, value: 250 },
              { timestamp: 1715126400000, value: 250 },
              { timestamp: 1715212800000, value: 250 },
              { timestamp: 1715299200000, value: 250 },
              { timestamp: 1715385600000, value: 250 },
              { timestamp: 1715472000000, value: 250 },
              { timestamp: 1715558400000, value: 250 },
              { timestamp: 1715644800000, value: 250 }
            ]
          },
          units: {
            show_in_chart: true,
            show_in_table: true,
            units: 'units',
            values: [
              { timestamp: 1714262400000, value: 222 },
              { timestamp: 1714348800000, value: 242 },
              { timestamp: 1714435200000, value: 123 },
              { timestamp: 1714521600000, value: 244 },
              { timestamp: 1714608000000, value: 233 },
              { timestamp: 1714694400000, value: 124 },
              { timestamp: 1714780800000, value: 245 },
              { timestamp: 1714867200000, value: 531 },
              { timestamp: 1714953600000, value: 250 },
              { timestamp: 1715040000000, value: 250 },
              { timestamp: 1715126400000, value: 250 },
              { timestamp: 1715212800000, value: 250 },
              { timestamp: 1715299200000, value: 250 },
              { timestamp: 1715385600000, value: 250 },
              { timestamp: 1715472000000, value: 250 },
              { timestamp: 1715558400000, value: 250 },
              { timestamp: 1715644800000, value: 250 }
            ]
          },
          conversion_rate: {
            show_in_chart: false,
            show_in_table: true,
            minor: true,
            units: '%',
            values: [
              { timestamp: 1714262400000, value: 34 },
              { timestamp: 1714348800000, value: 34 },
              { timestamp: 1714435200000, value: 32 },
              { timestamp: 1714521600000, value: 31 },
              { timestamp: 1714608000000, value: 30 },
              { timestamp: 1714694400000, value: 29 },
              { timestamp: 1714780800000, value: 30 },
              { timestamp: 1714867200000, value: 21 },
              { timestamp: 1714953600000, value: 32 },
              { timestamp: 1715040000000, value: 33 },
              { timestamp: 1715126400000, value: 34 },
              { timestamp: 1715212800000, value: 35 },
              { timestamp: 1715299200000, value: 33 },
              { timestamp: 1715385600000, value: 34 },
              { timestamp: 1715472000000, value: 35 },
              { timestamp: 1715558400000, value: 34 },
              { timestamp: 1715644800000, value: 35 }
            ]
          },
          cogs: {
            show_in_chart: false,
            show_in_table: true,
            show_per_unit: true,
            units: '$',
            values: [
              { timestamp: 1714262400000, value: 55 },
              { timestamp: 1714348800000, value: 60 },
              { timestamp: 1714435200000, value: 75 },
              { timestamp: 1714521600000, value: 80 },
              { timestamp: 1714608000000, value: 44 },
              { timestamp: 1714694400000, value: 22 },
              { timestamp: 1714780800000, value: 66 },
              { timestamp: 1714867200000, value: 87 },
              { timestamp: 1714953600000, value: 60 },
              { timestamp: 1715040000000, value: 50 },
              { timestamp: 1715126400000, value: 44 },
              { timestamp: 1715212800000, value: 77 },
              { timestamp: 1715299200000, value: 44 },
              { timestamp: 1715385600000, value: 55 },
              { timestamp: 1715472000000, value: 88 },
              { timestamp: 1715558400000, value: 34 },
              { timestamp: 1715644800000, value: 76 }
            ]
          }
        },
        products_summary:
          '<h3>SKUs:</h3><ul><li clickable="true" id="SKU_1">SKU_1</li><li clickable="true" id="SKU_2">SKU_2</li><li clickable="true" id="SKU_3">SKU_3</li></ul>'
      }
    }
  },
  anomalies: {
    Anomaly: [[1714608000000, 10860.5358]],
    'Missed target': [[1715385600000, 8291.9158]],
    'Missed target2': [[1715040000000, 9117.8339]]
  }
};

mock.onGet(API.insights.chart).reply(async config => {
  console.log('API.insights.chart', config);
  await new Promise(resolve => setTimeout(resolve, 750));
  return [200, chartEndUserResponse];
});

mock.onGet(API.insights.prompts).reply(async config => {
  console.log('insights.analyze prompts', config);
  await new Promise(resolve => setTimeout(resolve, 250));
  return [
    200,
    {
      options: [
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v1.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v2.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v3.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v4.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v5.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v6.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v7.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v8.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v9.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v10.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v11.0',
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v12.0'
      ],
      active:
        'Analyse changes in P&L comparing with moving average in P&L comparing with moving average v1.0'
    }
  ];
});

mock.onPatch(API.insights.prompts).reply(async config => {
  console.log('insights.analyze prompts', config);
  await new Promise(resolve => setTimeout(resolve, 250));
  return [200];
});

const skuDetailsResponse = {
  drivers: {
    gross_profit_wbr_calc: {
      type: 'main',
      units: '$',
      value: 100,
      prev_value: 50
    },
    sub_metric_1: {
      minor: true,
      units: '$',
      value: 100,
      prev_value: 50
    },
    units: {
      units: 'units',
      value: 100,
      prev_value: 50
    },
    conversion_rate: {
      minor: true,
      units: '%',
      value: 34,
      prev_value: 32
    },
    cogs: {
      show_per_unit: true,
      units: '$',
      value: 55,
      prev_value: 60
    }
  },
  summary: '<h3>SKU_1 details</h3>'
};

mock.onGet(API.insights.details).reply(async config => {
  console.log('API.insights.details', config);
  await new Promise(resolve => setTimeout(resolve, 250));
  const { params } = config;
  if (params && params.type === 'sku') {
    return [200, skuDetailsResponse];
  }

  return [200, { summary: `<h3>${params.id} details</h3>` }];
});
