import mock from './mock';

import './agency/agency-clients-db';
import './common/login-db';
import './common/profile-db';
import './app/metrix-db';
import './app/notes-list-db';
import './app/sales-db';
import './app/products-search-list';
import './app/billing-history-db';
import './app/orders-db';
import './app/products-db';
import './app/vat-db';
import './app/refunds-db';
import './app/email-tool-db';
import './app/reports-db';
import './app/bookmarks-db';
import './app/expenses-db';
import './app/advertising-db';
import './app/sqp-products-db';
import './app/monthly-brand-review-db';
import './app/pnl-dashboard-data-db';
import './app/insights-db';

mock.onAny().passThrough();

if (process.env.REACT_APP_DOMAIN) {
  mock.restore();
}
