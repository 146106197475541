import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getMetricByTitle, metrixInfo } from '@sm/metrix';
import { isSmallSize } from '@sm/WindowSizes';
import GetCurrency from 'seller/containers/GetCurrency';

import ModernBreakdown from '@components/modern-breakdown';
import { InfoIcon } from '@components/icons';
import { HEADER_HEIGHT, SELECTOR_VIEW_HEIGHT } from '@sm/layout';
import NotesList from 'seller/containers/notes-list/NotesList';
import SystemNotificationView from 'seller/containers/system-notification';
import Totals from 'seller/containers/totals';
import { uploadCsv } from 'seller/containers/products-selector-view/csvHelpers';
import {
  addNoteAction,
  deleteNoteAction,
  editNoteAction,
  setIntervalChanged as setIntervalChangedAction,
  setMetricChanged as setMetricChangedAction,
  setSelectedProducts as setSelectedProductsAction,
  updateDashboard as updateDashboardAction
} from 'seller/store/actions';
import HorizontalProductListContainer from '@components/products/products-horizontal-list/HorizontalProductListContainer';
import { NoOrdersDialog } from '@components';
import { DECIMAL_SCALE, getNumber } from '@utils';
import ROUTES from 'seller/routing';
import Colors from 'styles/colors';
import ProductsTable from './products/ProductsTable';
import Chart from './chart/Chart';
import SearchWidgetContainer from '../../containers/selector-view/SearchWidgetContainer';
import { BookmarkTabButton } from '../../containers/bookmark-search';
import {
  getPrepareNetProfitBreakdownDataFunc,
  getTotalsForBreakdown,
  prepareAdvertisingBreakdownData,
  prepareSalesBreakdownData
} from './prepareModernBreakdownData';

const chartScales = ['Hourly', 'Daily', 'Weekly', 'Monthly'];
const chartMetrics = Object.keys(metrixInfo).map(metricKey => metrixInfo[metricKey].title);

const DashBoard = props => {
  const {
    productSearch,
    totals,
    totalsLoading,
    sales,
    salesLoading,
    updateDashboard,
    metricsByDt,
    setIntervalChanged,
    setMetricChanged,
    primaryMetric,
    secondaryMetric,
    secondaryMetricsData,
    disabledChartInterval,
    interval,
    chartLoading,
    secondaryChartLoading,
    dateRange,
    marketplaceID,
    setSelectedProducts,
    marketplaces,
    lifetime,
    notes,
    addNote,
    deleteNote,
    editNote,
    disableDataFetching,
    serverUnavailable,
    ui,
    userPreferences,
    noMarketplaces,
    history
  } = props;
  useEffect(() => {
    if (!disableDataFetching && !serverUnavailable) updateDashboard();
  }, [
    disableDataFetching,
    productSearch.selectedProducts,
    updateDashboard,
    serverUnavailable,
    dateRange,
    marketplaceID
  ]);
  const selectedProductsView = (
    <HorizontalProductListContainer
      bookmarkButton={<BookmarkTabButton />}
      data={productSearch.selectedProducts}
      updateList={setSelectedProducts}
    />
  );

  const contentClassName = classNames({
    'overflow-auto': !productSearch.active,
    'overflow-hidden': productSearch.active
  });

  const currency = GetCurrency();

  const onExportCsv = breakdownData => {
    const preparedBreakdownData = getPrepareNetProfitBreakdownDataFunc()(breakdownData);
    const breakdownColumns = { Orders: 'Orders', Units: 'Units' };
    const breakdownValues = [breakdownData.orders, breakdownData.units];
    const getColumnNameValue = row => {
      if (!row.title) return;
      if (row.title === 'CIN7' && row.value === undefined) return;
      const metricSuffix = breakdownColumns[row.title] ? ' (Child)' : '';
      const metricName = row.title + metricSuffix;
      breakdownColumns[metricName] = metricName;
      breakdownValues.push(getNumber(row.value).toFixed(DECIMAL_SCALE));
    };
    preparedBreakdownData.forEach(row => {
      getColumnNameValue(row);
      if (row.children) row.children.forEach(getColumnNameValue);
    });
    uploadCsv([breakdownValues], breakdownColumns, 'Seller-Metrix-Detailed-Breakdown');
  };

  const totalsConfig = { ...metrixInfo };

  const commonBreakdownProps = {
    currency,
    buttonContent: <InfoIcon className="h-4 w-4" fill={Colors.gray.default} />,
    buttonClassName: 'ml-1 h-6 w-6 rounded-full',
    offset: '-5px, 5px',
    placement: isSmallSize() ? 'bottom' : 'right-start',
    getData: callback => callback(getTotalsForBreakdown(totals))
  };

  totalsConfig.sales.breakdown = (
    <ModernBreakdown
      {...commonBreakdownProps}
      buildViewData={prepareSalesBreakdownData}
      headerTitle="Sales Breakdown"
      buttonContent={<InfoIcon className="h-4 w-4" fill={Colors.white.default} />}
    />
  );

  totalsConfig.profit.breakdown = (
    <ModernBreakdown
      {...commonBreakdownProps}
      buildViewData={getPrepareNetProfitBreakdownDataFunc()}
      onDownload={onExportCsv}
      headerTitle="Net Profit Breakdown"
    />
  );

  totalsConfig.total_ads.breakdown = (
    <ModernBreakdown
      {...commonBreakdownProps}
      buildViewData={prepareAdvertisingBreakdownData}
      headerTitle="Advertising Breakdown"
      disablePercentageView
    />
  );

  return (
    <div
      style={{
        height: `calc(100% - ${HEADER_HEIGHT} - ${SELECTOR_VIEW_HEIGHT})`
      }}
      className="flex relative flex-col bg-white-dark scroll-container z-0"
    >
      <NoOrdersDialog open={noMarketplaces && history.location.pathname === ROUTES.home} />
      <SearchWidgetContainer />

      <div className={contentClassName}>
        <div className="lg:px-6 lg:py-4 xxs:p-2">
          {selectedProductsView}
          <SystemNotificationView />
          <Totals
            values={getTotalsForBreakdown(totals, true)}
            totalsLoading={totalsLoading}
            currency={currency}
            metrics={totalsConfig}
          />
          <Chart
            values={metricsByDt}
            interval={interval}
            end={dateRange.to}
            secondaryMetricsData={secondaryMetricsData}
            primaryMetricInfo={metrixInfo[primaryMetric]}
            onPrimaryMetricChanged={title => setMetricChanged(getMetricByTitle(title), true)}
            secondMetricInfo={metrixInfo[secondaryMetric]}
            onSecondaryMetricChanged={title => setMetricChanged(getMetricByTitle(title), false)}
            currency={currency}
            chartLoading={chartLoading || secondaryChartLoading}
            onIntervalChanged={newInterval => setIntervalChanged(newInterval)}
            disabledInterval={disabledChartInterval}
            scales={chartScales}
            metrics={chartMetrics}
          />
          <NotesList
            data={notes}
            dateRange={dateRange}
            onNoteAdd={(note, date, marketplace, tag) => {
              console.log(note, date, marketplace, tag);
              addNote(note, date, marketplace, tag);
            }}
            onNoteEdit={editNote}
            onNoteDelete={deleteNote}
            marketplaces={marketplaces}
            lifetime={lifetime}
            ui={ui}
          />
          <div className="lg:h-6 xxs:h-2 w-full" />
          <ProductsTable
            salesLoading={salesLoading}
            productMetrix={sales.products}
            currency={currency}
            dateRange={dateRange}
            marketplaceID={marketplaceID}
            userPreferences={userPreferences}
          />
        </div>
      </div>
    </div>
  );
};

DashBoard.propTypes = {
  ui: PropTypes.instanceOf(Object).isRequired,
  userPreferences: PropTypes.instanceOf(Object).isRequired,
  productSearch: PropTypes.instanceOf(Object).isRequired,
  setSelectedProducts: PropTypes.func.isRequired,
  totals: PropTypes.instanceOf(Object).isRequired,
  totalsLoading: PropTypes.bool.isRequired,
  chartLoading: PropTypes.bool.isRequired,
  sales: PropTypes.instanceOf(Object).isRequired,
  salesLoading: PropTypes.bool.isRequired,
  metricsByDt: PropTypes.instanceOf(Array).isRequired,
  updateDashboard: PropTypes.func.isRequired,
  setIntervalChanged: PropTypes.func.isRequired,
  setMetricChanged: PropTypes.func.isRequired,
  secondaryMetric: PropTypes.string,
  secondaryMetricsData: PropTypes.instanceOf(Array),
  disabledChartInterval: PropTypes.string,
  interval: PropTypes.string.isRequired,
  dateRange: PropTypes.instanceOf(Object).isRequired,
  marketplaceID: PropTypes.number,
  marketplaces: PropTypes.instanceOf(Array).isRequired,
  lifetime: PropTypes.instanceOf(Date).isRequired,
  // NOTES.
  notes: PropTypes.instanceOf(Array).isRequired,
  addNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  editNote: PropTypes.func.isRequired,
  disableDataFetching: PropTypes.bool,
  noMarketplaces: PropTypes.bool,
  serverUnavailable: PropTypes.bool.isRequired,
  orderEventType: PropTypes.string.isRequired
};

DashBoard.defaultProps = {
  secondaryMetric: null,
  secondaryMetricsData: null,
  disabledChartInterval: null,
  marketplaceID: 0,
  noMarketplaces: false,
  disableDataFetching: false
};

function mapStateToProps(state) {
  return {
    ui: state.ui,
    userPreferences: state.userPreferences,
    productSearch: state.productSearch,
    marketplaceID: state.selector.marketplaceID,
    orderEventType: state.dashboard.orderEventType,
    totals: state.dashboard.totals,
    totalsLoading: state.dashboard.totalsLoading,
    sales: state.dashboard.sales,
    salesLoading: state.dashboard.salesLoading,
    metricsByDt: state.dashboard.metricsByDt,
    interval: state.dashboard.interval,
    primaryMetric: state.dashboard.primaryMetric,
    secondaryMetric: state.dashboard.secondaryMetric,
    secondaryMetricsData: state.dashboard.secondaryMetricsData,
    disabledChartInterval: state.dashboard.disabledChartInterval,
    chartLoading: state.dashboard.chartLoading,
    secondaryChartLoading: state.dashboard.secondaryChartLoading,
    marketplaces: state.user.profile.marketplaces,
    noMarketplaces: state.user.profile.no_marketplaces,
    lifetime: state.user.profile.start,
    serverUnavailable: state.network.serverUnavailable,
    // FIXME: Need end of range for displaying correctly last date range.
    // Maybe it's better to pass end of the selected range from API ?
    dateRange: state.selector.dateRange,
    notes: state.notes.list
  };
}

export default connect(
  mapStateToProps,
  {
    setSelectedProducts: setSelectedProductsAction,
    updateDashboard: updateDashboardAction,
    setIntervalChanged: setIntervalChangedAction,
    setMetricChanged: setMetricChangedAction,
    addNote: addNoteAction,
    deleteNote: deleteNoteAction,
    editNote: editNoteAction
  }
)(withRouter(DashBoard));
