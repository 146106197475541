import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isObject } from '@utils';

const MenuComponent = ({ id, data, anchor, handleClose, disabledItem, className }) => {
  return (
    <Menu
      MenuListProps={{ style: { padding: 0 } }}
      id={id}
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {data.map(dataItem => {
        const dataItemValue = isObject(dataItem) && 'value' in dataItem ? dataItem.value : dataItem;
        const dataItemView = isObject(dataItem) && 'view' in dataItem ? dataItem.view : dataItem;
        return (
          <MenuItem
            disabled={disabledItem === dataItemValue}
            key={dataItemValue}
            onClick={() => handleClose(dataItemValue)}
          >
            <div className={classNames('capitalize', className)}> {dataItemView} </div>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

MenuComponent.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  anchor: PropTypes.instanceOf(Object),
  handleClose: PropTypes.func.isRequired,
  disabledItem: PropTypes.string,
  className: PropTypes.string
};

MenuComponent.defaultProps = {
  anchor: null,
  disabledItem: null,
  className: ''
};

export default MenuComponent;
